<template>
  <CRow class="justify-content-center m-0 pt-3">
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedLocationList"
        :fields="fields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Quantity="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Quantity, 2) }}
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater, NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    
  };
}

//methods
function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

//computed
function computedLocationList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.form?.Location?.UbicationJson?.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      PackagingName: item[`PackagingName${_lang}`] ?? '',
      YardAlias: item?.YardBlDetailJson?.[0]?.YardAlias ?? 'N/A',
      Quantity: item?.YardBlDetailJson?.[0]?.Quantity ?? 0,
      YardAreaName: item?.YardBlDetailJson?.[0]?.YardAreaName ?? 'N/A',
      DaysInYard: item?.YardBlDetailJson?.[0]?.DaysInYard ?? 0,
      ComputedBlCargoDetailMinDate: item?.YardBlDetailJson?.[0]?.BlCargoDetailMinDate ? DateFormater.formatOnlyDateWithSlash(item?.YardBlDetailJson?.[0]?.BlCargoDetailMinDate) : 'N/A',
    };
  });
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:5%; text-align:left', filter: false},
    { label: this.$t('label.yard'), key: 'YardAlias',_style: 'width:21%;',_classes: 'text-uppercase text-center'},
    { label: this.$t('label.packaging'), key: 'PackagingName',_style: 'width:20%;',_classes: 'text-uppercase text-center'},
    { label: this.$t('label.quantity'), key: 'Quantity',  _classes: 'text-uppercase text-center', _style: 'width:12%;', },
    { label: this.$t('label.module'), key: 'YardAreaName', _classes: 'text-uppercase text-center', _style:'width:15%' },
    { label: `${this.$t('label.Day')}S`, key: 'DaysInYard', _classes: 'text-center text-uppercase', _style:'width:12%' },
    { label: this.$t('label.date'), key: 'ComputedBlCargoDetailMinDate',_classes:'text-center', _style:'width:15%' },
  ];
}

export default {
  name: 'location',
  components: {
  
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  mixins: [
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    formatNumber,
  },
  computed: {
    computedLocationList,
    fields,
  },
  watch: {
  }
};
</script>